<template>
    <div class="title-box">
        <div
            class="title-text"
            @click="toCounty"
            style="cursor: pointer;"
        >
            <p>汀祖镇数字乡村</p>
            <p>汀祖镇数字乡村</p>
            <span>Digital Village of Tingzu Town</span>
        </div>
        <!-- <div class="web-link">
			<div v-for="(item,index) in menuList" @click="toLinkWeb(item.link,item.name)" :key="index" class="web-link-item">
				<div class="img"></div>
				<span>{{item.name}}</span>
			</div>
		</div> -->
        <menu-list />
        <div class="user-info-box">
            <div class="village-list">
                <line-border-box />
                <!-- @click="countyList_state=!countyList_state" -->
                <div
                    class="village-box"
                    @click="countyList_state=!countyList_state"
                >
                    <span v-if="villageList.length>0">{{town_name}}</span>
                    <!-- <span v-else-if="villageList.length=1">{{menu_name}}</span> -->
                    <span v-else>{{menu_name}}</span>
                    <!-- <span v-if="villageList.length>0" class="iconfont" :style="{transform:countyStyle}">&#xe625;</span> -->
                    <span
                        v-if="villageList.length>0"
                        class="iconfont"
                        :style="{transform:countyStyle}"
                    >&#xe625;</span>
                </div>
                <div
                    class="village-change"
                    v-if="countyList_state && villageList.length>0"
                >
                    <div class="county-li">
                        <div
                            class="village-ti"
                            @click="changeTown"
                        >
                            <span>{{villageList_icon}}</span>
                            <span>汀祖镇</span>
                        </div>
                        <!-- <div class="village-li"  @click="changeVillage(index)" :class="menu_name==item.name?'village_cur':''" v-if="villageList_icon=='+'"  v-for="(item,index) in villageList" :key="index"> -->
                        <div
                            class="village-li"
                            @click="changeVillage(index)"
                            :class="menu_name==item.name?'village_cur':''"
                            v-for="(item,index) in villageList"
                            :key="index"
                        >
                            <div style="display: flex;">
                                <span></span>
                                <span>{{item.name}}</span>
                            </div>
                            <div>
                                <p></p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="time">{{time.split(" ")[1]}}</div>
            <div class="day">
                <span>{{time.split(" ")[2]}}</span>
                <span>{{time.split(" ")[0]}}</span>
            </div>
            <div class="user-info">
                <div class="img"><img :src="user_icon"></div>
                <div class="name">
                    <span>用户：</span>
                    <span>{{ userInfo.name || '超级' }}管理员</span>
                </div>
            </div>
            <div
                class="quit"
                @click="clearLogin"
            >
                <span class="icon iconfont">&#xe81b;</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import lineBorderBox from "@/components/line-border-box.vue"
import { getToken } from '@/util/auth.js'
import menuList from "@/components/menuList/menuList"

export default {
    components: { lineBorderBox, menuList },
    data() {
        return {
            town_name: '汀祖镇',
            time: '',
            timeInterval: '',
            userInfo: {},
            itemActive: 1,
            routeList: [],
            webState: 'village',
            // 下拉菜单状态
            select_option_state: false,
            villageList: [],
            menu_name: '',  //localStorage.getItem('tenantName'),
            user_icon: localStorage.getItem('webState') == 'town' ? require('@/assets/new_img/county-icon.png') : require('@/assets/new_img/village-icon.png'),
            // 菜单列表
            // menuList: [
            // 	{
            //     name: '数字农业',
            //     link: ''
            // }, {
            //     name: '智慧党建',
            //     link: ''
            // }
            // ],
            menuList: [
                {
                    name: '规划图',
                    link: ''
                },
                {
                    name: '生产图',
                    link: ''
                },
                {
                    name: '治理图',
                    link: ''
                },
                {
                    name: '环境图',
                    link: ''
                },
                {
                    name: '服务图',
                    link: ''
                }
            ],
            countyList_state: false,
            villageList_icon: '-',
            countyStyle: 'rotate(180deg)'
        }
    },
    created() {
        let name = localStorage.getItem('tenantNameActive')
        if (name) {
            this.town_name = name
        }
        console.log('--town_name---',name)
    },
    mounted() {
        this.getTime()
        this.getUserInfo()
        this.initMenu()

        // this.menuList[0].link = 'https://bk-planting.eaia.cn/#/'+this.token
        // this.menuList[1].link = 'https://wparty.eaia.cn/login'+this.token

        // 只有中坪村有三资且中坪登录之后不显示旅游
        // let sanzi = {
        // 	    name: '三资管理',
        // 	    link: 'https://bk-capital.eaia.cn/user/login'+this.token
        // 	}
        // let lvyou = {
        // 	    name: '智慧旅游',
        // 	    link: 'https://bkmq.worldmaipu.com/screen/index.html'+this.token
        // 	}
        // if(this.menu_name == '保康县' || this.menu_name == '中坪村'){
        // 	this.menuList[2] = sanzi
        // 	this.menuList[3] = lvyou
        // }else{
        // 	this.menuList[2] = lvyou
        // }
        if (localStorage.getItem('webState')) {
            let countyData = JSON.parse(sessionStorage.getItem('token'))
            
            this.webState = localStorage.getItem('webState');console.log('--this.webState---',this.webState, this.$route)
            if (this.webState == 'town') {
                if (countyData.tenant) {
                    this.villageList = countyData.tenant.child
                    this.town_name = countyData.tenant.name
                }
            } else if (this.webState == 'county') {
                if (countyData.tenant) {
                    this.villageList = countyData.tenant.child[0].child
                    this.town_name = countyData.tenant.name
                }
            }

            if(this.$route.path != '/town' && this.$route.path != '/county'){
                this.town_name = localStorage.getItem('tenantNameActive');
            }
        }
    },
    watch: {
        select_option_state: function (newVal, oldVal) {
            if (newVal) {
                setTimeout(res => {
                    this.select_option_state = false
                }, 10000)
            }
        },
        town_name: function (newVal, oldVal) {
            console.log('--this.town_name--', newVal)
        },
        $route: function (newVal, oldVal) {
            // newVal.name == 'town' ? this.menu_name = '大畈镇' : this.menu_name = localStorage.getItem('tenantName')
            if (newVal.name == 'town') {
                this.menu_name = '汀祖镇'
            } else if (newVal.name == 'county') {
                this.menu_name = '鄂城区'
            } else {
                this.menu_name = localStorage.getItem('tenantName')
            }
            let sanzi = {
                name: '三资管理',
                link: 'https://bk-capital.eaia.cn/user/login' + this.token
            }
            let lvyou = {
                name: '智慧旅游',
                link: 'https://bkmq.worldmaipu.com/screen/index.html' + this.token
            }
            this.town_name = this.menu_name
            console.log('menu_name--',this.town_name)
            if (this.menu_name == '汀祖镇') {
                this.menuList[2] = sanzi
                this.menuList[3] = lvyou
            }
            for (let [index, item] of this.routeList.entries()) {
                if (newVal.path == '/') {
                    this.itemActive = 1
                } else if (newVal.path == '/' + item.path) {
                    this.itemActive = index + 1
                }
            }
        },
        countyList_state: function (newVal, oldVal) {
            if (newVal) {
                this.countyStyle = 'rotate(180deg)'
            } else {
                this.countyStyle = 'rotate(0deg)'
            }
        }
    },
    computed: {
        ...mapState(['menu_active']),
        token: function () {
            if (localStorage.getItem('webState') == 'county') {
                return ''
            } else {
                return '?token=' + getToken()
            }
        }
    },
    methods: {
        changList() {

            this.countyList_state = !this.countyList_state
            this.villageList_icon == '-' ? this.villageList_icon = '+' : this.villageList_icon = '-'
        },
        changeTown() {
            this.town_name = "汀祖镇"
            this.countyList_state = false
            this.$router.push({ path: '/town' })
            //  localStorage.removeItem('tenantName')
            // localStorage.removeItem('tenantCode')
            // localStorage.removeItem('tenantId');
            localStorage.removeItem('tenantNameActive');
        },
        getTime: function () {
            this.timeInterval = setInterval(() => {
                this.time = this.getNowData()
            }, 1000)
        },
        initMenu: function () {
            let _this = this
            this.routeList = this.$router.options.routes[0].children.slice(0, 5)
            _this.$router.onReady(() => {
                for (let [index, item] of _this.routeList.entries()) {
                    if (_this.$route.path == '/') {
                        _this.itemActive = 1
                    } else if (_this.$route.path == '/' + item.path) {
                        _this.itemActive = index + 1
                    }
                }
            });
            this.routeList[0].children = ['自然风貌', '村庄变迁', '空间规划']
            this.routeList[1].children = ['土地用途', '产业布局', '乡村经营主体', '农业设备']
            this.routeList[2].children = ['村组管理', '人口管理', '特殊人群', '乡村安防']
            this.routeList[3].children = ['村容村貌', '厕所革命', '垃圾污水', '乡村电力', '乡村水利', '乡村基建']
            this.routeList[4].children = ['农机服务', '文化生活', '体育运动', '医疗养老', '其它配套']
            this.$route.name == 'county' ? this.webState = 'county' : this.webState = 'village'
            this.$route.name == 'county' ? this.menu_name = '鄂城区' : this.menu_name = localStorage.getItem('tenantName')
        },
        // 获取用户信息
        getUserInfo: function () {
            let webState = localStorage.getItem('webState')
            if (webState == 'town') {
                this.userInfo.name = '汀祖镇'
            } else if (webState == 'county') {
                this.userInfo.name = '鄂城区'
            } else {
                this.userInfo.name = localStorage.getItem('tenantName')
            }

        },
        getNowData: function () {
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear() // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            var weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            var week = weekArr[date.getDay()];
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds + " " + week;
        },
        clearLogin: function () {
            this.$confirm('此操作将退出登录返回登录页, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.commit('changeActive', 1)
                localStorage.clear()
                this.$router.push({ path: '/login' })
            }).catch(() => {

            });
        },
        toCounty: function () {
            if (this.webState == 'county') {
                this.select_option_state = false
                this.$router.push({ path: '/county' })
            }
            if (this.webState == 'town' & this.$route.name != 'town') {
                this.select_option_state = false
                this.$router.push({ path: '/town' })
            }
        },
        changeVillage(index) {
            this.select_option_state = false
            this.countyList_state = false
            this.menu_name = this.villageList[index].name
            this.town_name = this.villageList[index].name
            localStorage.setItem('tenantNameActive', this.villageList[index].name)
            localStorage.setItem('tenantName', this.villageList[index].name)
            localStorage.setItem('tenantCode', this.villageList[index].code)
            localStorage.setItem('tenantId', this.villageList[index].id);
            console.log('99',index);
            this.$parent.requestMapConfig();//更新父组件的地图配置
            // this.$router.push({ path: '/tutorials' })
            if (this.$route.name == 'town' || this.$route.name == 'county') {
                this.$router.push({ path: '/tutorials' })
            } else {
                this.$router.go(0)
            }

        },
        toLinkWeb: function (link, name) {
            if (link) {
                window.open(link)
            } else {
                this.$notify({
                    title: '警告',
                    message: `暂未配置${name}跳转链接！`,
                    type: 'warning'
                });
            }
        }
    },
    destroyed() {
        clearInterval(this.timeInterval)
    }
}
</script>
<style lang="scss" scoped>
@font-face {
    font-family: 'title-font';
    src: url('../../assets/font/1586946283769.ttf');
}
.village-ti:hover {
    color: #00ffff;

    span:first-child {
        border-color: #00ffff !important;
    }
}

.title-box {
    box-sizing: border-box;
    width: 100%;
    height: 0.9rem;
    position: absolute;
    background-color: #02204e;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border-bottom: 0.04rem solid;
    border-image: linear-gradient(
            90deg,
            rgba(4, 43, 102, 0),
            rgba(9, 97, 229, 1),
            rgba(102, 184, 255, 1),
            rgba(9, 97, 229, 1),
            rgba(4, 43, 102, 0)
        )
        4 4;
    z-index: 999;
    padding: 0 0.32rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-text {
        display: flex;
        flex-direction: column;
        width: 20%;
        height: 80%;
        p {
            display: inline-block;
            font-size: 0.4rem;
            line-height: 0.4rem;
            font-family: title-font;
            font-weight: 400;
            letter-spacing: 0.04rem;
            color: #fff;
        }
        p:first-child {
            text-shadow: -0.02rem -0.02rem 0.2rem #0055ff;
        }
        p:nth-child(2) {
            transform: rotateX(-180deg) translateY(0.1rem);
            transform-origin: bottom;
            filter: blur(0.01rem);
            opacity: 0.3;
            position: absolute;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            background-image: linear-gradient(
                to bottom,
                transparent 30%,
                #eee 80%
            );
            -webkit-background-image: linear-gradient(
                to bottom,
                transparent 30%,
                #eee 80%
            );
        }

        span {
            font-size: 0.16rem;
            font-weight: 500;
            color: #5a7295;
            line-height: 0.24rem;
            text-transform: uppercase;
        }
    }
    .web-link {
        display: flex;
        font-family: title-font;
        font-weight: 400;
        font-size: 0.3rem;
        line-height: 0.38rem;
        -webkit-background-clip: text;
        margin-left: 0.42rem;
        color: #bbb;
        // -webkit-text-fill-color: transparent;
        .web-link-item {
            width: 1.6rem;
            height: 0.6rem;
            margin-right: 0.2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .img:first-child {
                width: 0.72rem;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                }
            }
            span {
                opacity: 0.4;
            }
        }
        .web-link-item:hover {
            color: #00ffff;
            span {
                opacity: 1;
            }
        }
    }
    .user-info-box {
        font-size: 0.14rem;
        color: #fff;
        display: flex;
        align-items: center;
        .village-list {
            position: relative;
            width: 1.04rem;
            height: 0.32rem;
            padding: 0.02rem;
            box-sizing: border-box;
            .village-box {
                width: 99%;
                height: 99%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                background-image: linear-gradient(
                    to right,
                    #000 0.01rem,
                    transparent 0.04rem
                );
                background-size: 0.06rem 100%;
                cursor: pointer;
                span:last-child {
                    color: #00ffff;
                    font-size: 0.16rem;
                }
            }
            .village-change {
                position: absolute;
                width: 100%;
                background: rgba(2, 22, 51, 0.5);
                box-sizing: border-box;
                backdrop-filter: blur(0.02rem);
                padding: 0.12rem 0.08rem;
                box-sizing: border-box;
                .county-li {
                    .village-ti {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        span {
                            cursor: pointer;
                            font-size: 0.14rem;
                        }
                        span:first-child {
                            display: flex;
                            width: 0.14rem;
                            height: 0.14rem;
                            font-size: 0.14rem;
                            justify-content: center;
                            align-items: center;
                            line-height: 0.14rem;
                            margin-right: 0.1rem;
                            border: 0.01rem solid #fff;
                        }
                    }
                    .village-li {
                        padding: 0.08rem 0 0 0.1rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 0.12rem;
                        span:first-child {
                            display: flex;
                            width: 0.1rem;
                            height: 0.1rem;
                            border: 0.01rem dashed transparent;
                            border-left-color: #fff;
                            border-bottom-color: #fff;
                            transform: translateY(-0.04rem);
                            -webkit-transform: translateY(-0.04rem)
                                translateX(-0.02rem);
                        }
                        div:last-child {
                            width: 0.12rem;
                            height: 0.12rem;
                            border-radius: 50%;
                            border: 0.01rem solid #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            p {
                                width: 0.08rem;
                                height: 0.08rem;
                                border-radius: 50%;
                                background-color: #00ffff;
                                display: none;
                                line-height: 0.12rem;
                            }
                        }
                    }
                    .village-li:hover,
                    .village_cur {
                        cursor: pointer;
                        span {
                            color: #00ffff;
                            border-left-color: #00ffff;
                            border-bottom-color: #00ffff;
                        }
                        div:last-child {
                            border-color: #00ffff;
                            p {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
        .time {
            margin-left: 0.22rem;
            font-size: 0.36rem;
        }
        .day {
            display: flex;
            flex-direction: column;
            font-size: 0.12rem;
            color: #dce6f5;
            margin-left: 0.08rem;
        }
        .user-info {
            margin-left: 0.16rem;
            display: flex;
            .img {
                border: 0.01rem solid #ffffff;
                width: 0.32rem;
                height: 0.32rem;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }
            .name {
                display: flex;
                flex-direction: column;
                font-size: 0.12rem;
                color: #dce6f5;
                margin-left: 0.08rem;
            }
        }
        .quit {
            margin-left: 0.16rem;
            width: 0.32rem;
            height: 0.32rem;
            background-color: #043866;
            border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;

            display: flex;
            justify-content: center;
            align-items: center;
            span {
                font-size: 0.18rem;
            }
            cursor: pointer;
        }
    }
}
.web-link-item > img {
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
}
.web-link-item:nth-child(1) > .img {
    background-image: url('../../assets/img/gh.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.web-link-item:nth-child(2) > .img {
    background-image: url('../../assets/img/sc.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.web-link-item:nth-child(3) > .img {
    background-image: url('../../assets/img/zl.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.web-link-item:nth-child(4) > .img {
    background-image: url('../../assets/img/hj.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.web-link-item:nth-child(5) > .img {
    background-image: url('../../assets/img/fw.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
// .web-link-item:nth-child(1)>.img{
// 	background-image: url("../../assets/img/a1.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }
// .web-link-item:nth-child(2)>.img{
// 	background-image: url("../../assets/img/a2.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }.web-link-item:nth-child(3)>.img{
// 	background-image: url("../../assets/img/a3.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }.web-link-item:nth-child(4)>.img{
// 	background-image: url("../../assets/img/a4.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }
// .web-link-item:nth-child(1):hover>.img{
// 	background-image: url("../../assets/img/a1-s.png");
// }
// .web-link-item:nth-child(2):hover>.img{
// 	background-image: url("../../assets/img/a2-s.png");
// }.web-link-item:nth-child(3):hover>.img{
// 	background-image: url("../../assets/img/a3-s.png");
// }.web-link-item:nth-child(4):hover>.img{
// 	background-image: url("../../assets/img/a4-s.png");
// }
</style>