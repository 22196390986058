<template>
  <div class="home">
    <title-bar  @showMessage="showMessage" />
    <div id="cesiumContainer"></div>
    <div class="content">
      <router-view :basicData="basicData" @showMessage="showMessage" />
    </div>
    <!-- <menu-list /> -->

    <div v-if="noPage" class="noPage">
        {{messageText}}
    </div>
  </div>
</template>

<script>
import { getBasic } from '@/api/tutorials.js'
import titleBar from "@/components/titleBar/index"
import menuList from "@/components/menuList/menuList"

window.g_2d_imagery_arr = [];
var g_3d_name_arr = [];
window.g_map_type = 0;   //2二维  3三维 
window.g_map_2d_index = 0;  //保存多个二维地图时，当前显示的是哪个二维地图

export default {
  name: 'Home',
  components: { titleBar, menuList },
  data() {
    return {
      userInfo: {},
      g_camera_position: {},    // 初始化二维位置
      g_camera_3d_position: {}, // 初始化三维位置
      v_list_fly: [],           //飞行文件
      v_map_3d_list: [],        //三维
      v_map_2d_sl_list: [],     //二维矢量
      v_map_2d_yx_list: [],     //二维影像

      noPage:false,
      messageText:'',
      backgroundColor:'',
      color:'',
      popState:false,
      basicData:''
    }
  },
  watch: {
    $route: function (newVal, oldVal) {
      if (oldVal.name == 'county') {
        this.$router.go(0)
      }
    }
  },
  created() {

  },
  mounted() {
    this.init();
  },
  methods: {
    init: function () {
      // 初始化地球
      this.initGlobe();
      // 请求村地图配置
      this.requestMapConfig();
    },
    showMessage:function(e){
      if(e){
        this.messageText = e
      }else{
        this.messageText = '系统正在建设中，敬请期待...'
      }
      this.noPage = true
      setTimeout(() =>{
        this.noPage = false
      },2000)
    },
    requestMapConfig: function () {
      console.log("----请求村地图配置----", localStorage.getItem('tenantName'));
      window.parentMounted = false;
      g_map_type = 0;//还原地图类型
      g_3d_name_arr = [];
      getBasic().then(res => {
        this.basicData = JSON.stringify(res.data.villageProfile) 
        let feature = res.data.initialFeature

        //初始化二维位置
        this.g_camera_position = {
          "x": Number(feature.camera2PointX),
          "y": Number(feature.camera2PointY),
          "z": parseInt(feature.camera2PointZ)
        }
        //初始化三维位置
        this.g_camera_3d_position = {
          "x": Number(feature.camera3PointX),
          "y": Number(feature.camera3PointY),
          "z": parseInt(feature.camera3PointZ),
          "heading": Number(feature.camera3PointHeading),
          "pitch": Number(feature.camera3PointPitch),
          "roll": Number(feature.camera3PointRoll)
        }

        this.v_list_fly = res.data.flyList;
        this.v_map_3d_list = res.data.tiltList;
        this.v_map_2d_yx_list = res.data.flatList;
        window.g_map_2d_yx_list = this.v_map_2d_yx_list;
        console.log("----已得到村地图配置----", this._isMounted);
        window.parentMounted = true;
      })
    },
    show3DMap() {console.log("----开始加载三维数据----");
      if (g_3d_name_arr.length > 0) {
        this.show2DMap();
        return;
      }
      if(this.v_map_3d_list.length == 0){
        this.show2DMap();
        return;
      }
      this.delMap();
      //加载三维场景
      for (var q = 0; q < this.v_map_3d_list.length; q++) {
        var _layer_name = 'base' + q;
        viewer.scene.addS3MTilesLayerByScp(this.v_map_3d_list[q].url, { name: _layer_name });
        g_3d_name_arr.push(_layer_name);
      }
      g_map_type = 3;
      this.change3DViewer();
    },

    show2DMap() {
      if (g_2d_imagery_arr.length > 0) {
        return;
      }
      this.delMap();
      //加载二维
      for (var i = 0; i < this.v_map_2d_yx_list.length; i++) {
          if(g_map_2d_index == i){
              var _imagery = viewer.imageryLayers.addImageryProvider(new Cesium.SuperMapImageryProvider({
                url: this.v_map_2d_yx_list[i].url
              }));
              _imagery.transparentBackColor =  Cesium.Color.BLACK;  //白色背景WHITE 透明处理
              _imagery.transparentBackColorTolerance = 0.1;//背景 透明处理
              g_2d_imagery_arr.push(_imagery);
          }

      }
      g_map_type = 2;
      this.change2DViewer();
    },

    delMap() {
      for (var i = g_2d_imagery_arr.length - 1; i >= 0; i--) {
        if (g_2d_imagery_arr[i]) {
          viewer.imageryLayers.remove(g_2d_imagery_arr[i]);
          //g_2d_imagery_arr[i] = null;
          g_2d_imagery_arr.splice(i, 1);
        }
      }
      for (var i = g_3d_name_arr.length - 1; i >= 0; i--) {
        var _layerName = g_3d_name_arr[i];
        if (_layerName) {
          viewer.scene.layers.remove(_layerName);
          g_3d_name_arr.splice(i, 1);
        }
      }
    },

    //改变三维视角
    change3DViewer: function () {
      if(!this.g_camera_3d_position.x || !this.g_camera_3d_position.y){//没有三维配置，就不改变视角
        return;
      }
      var hcv = {
        //将经度、纬度、高度的坐标转换为笛卡尔坐标
        destination: Cesium.Cartesian3.fromDegrees(this.g_camera_3d_position.x, this.g_camera_3d_position.y, this.g_camera_3d_position.z),
        orientation: {
          heading: this.g_camera_3d_position.heading,
          pitch: this.g_camera_3d_position.pitch, //-1.570795,
          roll: this.g_camera_3d_position.roll
        }
      }
      viewer.camera.flyTo(hcv);
    },

    //改变二维视角
    change2DViewer: function () {
      var hcv = {
        //将经度、纬度、高度的坐标转换为笛卡尔坐标
        destination: Cesium.Cartesian3.fromDegrees(this.g_camera_position.x, this.g_camera_position.y, this.g_camera_position.z),
        orientation: {
          heading: 0.0,
          pitch: -1.44, //-1.570795,
          roll: 0.0
        }
      }
      viewer.camera.flyTo(hcv);
    },

    //得到maker的海拔高度，三维 100，二维10
    getZ() {
      if (g_map_type == 2) {
        return 10;
      }
      if (g_map_type == 3) {
        return 100;
      }
      return 0;
    },

    initGlobe() {
      console.log('----初始化地球----');
      window.viewer = new Cesium.Viewer('cesiumContainer', {
        infoBox: false,
        selectionIndicator: false,
        navigation: false
      });
      // viewer.scene.globe.show = false;
      // viewer.scene.skyBox.show = false;
      // viewer.scene.backgroundColor = Cesium.Color.fromCssColorString('#bbb');
      viewer.scene.globe.depthTestAgainstTerrain = true;
      var provider_img = new Cesium.TiandituImageryProvider({
        mapStyle: Cesium.TiandituMapsStyle.IMG_W, //天地图全球影像地图服务 
        token: this.$config.TDT_MAP_TOKEN//"62821db3fe578d3c8bc5e44ddb10aa75"
      });
      var provider_cia = new Cesium.TiandituImageryProvider({
        mapStyle: Cesium.TiandituMapsStyle.CIA_W, //天地图全球中文注记服务 
        token: this.$config.TDT_MAP_TOKEN//"62821db3fe578d3c8bc5e44ddb10aa75",
      });
      window.viewer.imageryLayers.addImageryProvider(provider_img);
      window.viewer.imageryLayers.addImageryProvider(provider_cia);

      // var provider = new Cesium.TiandituTerrainProvider({
      //   token: this.$config.TDT_MAP_TOKEN
      // });
      // viewer.terrainProvider = provider;

      //设置操作习惯,更换中键和右键
      viewer.scene.screenSpaceCameraController.tiltEventTypes = [
        Cesium.CameraEventType.RIGHT_DRAG, Cesium.CameraEventType.PINCH,
        { eventType: Cesium.CameraEventType.LEFT_DRAG, modifier: Cesium.KeyboardEventModifier.CTRL },
        { eventType: Cesium.CameraEventType.RIGHT_DRAG, modifier: Cesium.KeyboardEventModifier.CTRL }
      ];

      viewer.scene.screenSpaceCameraController.zoomEventTypes = [
        Cesium.CameraEventType.MIDDLE_DRAG, Cesium.CameraEventType.WHEEL, Cesium.CameraEventType.PINCH
      ];
  

      //正式发布时，注销此方法
      this.addMapClickEvent();

    },

    addMapClickEvent() {
      viewer.scene.camera.moveEnd.addEventListener(function () {
        //实现对应的移动结束操作
        var position = viewer.scene.camera.positionCartographic;
        var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(5);
        var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(5)
        /*        console.log('--相机参数', viewer.camera.position.x.toFixed(2), 
                  viewer.camera.position.y.toFixed(2), 
                  viewer.camera.position.z.toFixed(2), 
                  viewer.camera.heading.toFixed(2), 
                  viewer.camera.pitch.toFixed(2), 
                  viewer.camera.roll.toFixed(2),
                  '坐标', 
                  longitude, 
                  latitude,
                  position.height
                );*/
      });

      //三维地图单击事件
      var handler1 = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      //注销默认的双击事件，防止图标抖动
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      //调用setInputAction方法
      handler1.setInputAction((movement) => {
        this.get3DXYZ(movement.position);
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },

    get2DXYZ(position){
        var cartesian = viewer.camera.pickEllipsoid(position, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          //将笛卡尔三维坐标转为地图坐标（弧度）
          var cartographic = viewer.scene.globe.ellipsoid.cartesianToCartographic(cartesian);
          //将地图坐标（弧度）转为十进制的度数
          var lat = Cesium.Math.toDegrees(cartographic.latitude);
          var lon = Cesium.Math.toDegrees(cartographic.longitude);
          lat = Number(Number(lat).toFixed(5));
          lon = Number(Number(lon).toFixed(5));
          console.log(lon, lat, window.parseInt(cartographic.height));
        }
    },

    get3DXYZ(position){
        var cartesian = viewer.scene.pickPosition(position);
        if (Cesium.defined(cartesian)) {
            var cartographic = Cesium.Cartographic.fromCartesian(cartesian);
            var lng = Cesium.Math.toDegrees(cartographic.longitude);
            var lat = Cesium.Math.toDegrees(cartographic.latitude);
            lng = Number(Number(lng).toFixed(5));
            lat = Number(Number(lat).toFixed(5));
            var height = parseInt(cartographic.height);//模型高度
            console.log('坐标----经度', lng, '--纬度', lat, '--高程', height);
        }
    }
  },
  destroyed() {
     this.delMap();
  }
}
</script>
<style>
.cesium-viewer-bottom {
    display: none;
}
</style>
<style scoped>
.home {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#cesiumContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
</style>
<style lang="scss">
.home {
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  flex: 1;
}
.noPage{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  width: 3.61rem;
	height: .61rem;
  font-size: .16rem;
	border-radius: .04rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/new_img/message.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: .1rem;
  box-sizing: border-box;
}
</style>