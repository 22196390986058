<template>
	<div class="menuList">
		<div class="menuItem" v-for="(item,index) in routeList" :key="index" @click="changeMenu(item.path,index)">
			<!-- <img v-if="itemActive != index" :src="require('../../assets/new_img/menu'+index+'.png')" alt="">
			<img v-else :src="require('../../assets/new_img/menu'+index+'-s.png')" alt=""> -->
			
			<div :class="[itemActive == index?'active-img':'img']"></div>
			<span :class="{active:itemActive == index}">{{item.pageName}}</span>
			
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex"
	export default {
		data() {
			return {
				routeList: [],
				itemActive:0
			}
		},
		mounted() {
			this.initMenu()
		},
		watch: {
			$route:function(){
				this.initMenu()
			}
		},
		methods: {
			initMenu: function() {
				let _this = this
				this.routeList = this.$router.options.routes[0].children.slice(0, 5)
				_this.$router.onReady(() => {
					for (let [index, item] of _this.routeList.entries()) {
						if (_this.$route.path == '/') {
							_this.itemActive = 0
						} else if (_this.$route.path == '/' + item.path) {
							_this.itemActive = index
						} else if (_this.$route.path == '/county'){
							_this.itemActive = -1
						}
					}
				});
				console.log('---',this.routeList)
			},
			changeMenu(path,index){
				if (this.$route.path != '/' + path) {
				    this.$router.push({ path: path })
					this.itemActive = index
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
.menuList{
display: flex;
		font-family: title-font;
		font-weight: 400;
		font-size: 0.30rem;
		line-height: 0.38rem;
		-webkit-background-clip: text;
		margin-left: 0.42rem;
		color: #bbb;

	.menuItem {
			width: 1.6rem;
			height: 0.6rem;
			margin-right: 0.2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			.active-img,
			.img:first-child{
				width: 0.68rem;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				img{
					width: 100%;
				}
			}
			span{
				opacity: 0.4;
			}
		}
		.menuItem .active{
			color: #00FFFF;
			opacity: 1;
		}
		.menuItem:hover{
			color: #00FFFF;
			span{
				opacity: 1;
			}
			.menuItem>img{
	// background-size: 100% 100%;
	// background-repeat: no-repeat;
}

	}
	.menuItem:nth-child(1)>.active-img{
	background-image: url("../../assets/img/gh.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	}
	.menuItem:nth-child(1)>.img{
	background-image: url("../../assets/img/gh2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	}
	.menuItem:nth-child(2)>.active-img{
		background-image: url("../../assets/img/sc.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.menuItem:nth-child(2)>.img{
		background-image: url("../../assets/img/sc2.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.menuItem:nth-child(3)>.active-img{
		background-image: url("../../assets/img/zl.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.menuItem:nth-child(3)>.img{
		background-image: url("../../assets/img/zl2.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}	
	.menuItem:nth-child(4)>.active-img{
		background-image: url("../../assets/img/hj.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}	
	.menuItem:nth-child(4)>.img{
		background-image: url("../../assets/img/hj2.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}	
	.menuItem:nth-child(5)>.active-img{
		background-image: url("../../assets/img/fw.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}	
	.menuItem:nth-child(5)>.img{
		background-image: url("../../assets/img/fw2.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}	
}	
</style>
